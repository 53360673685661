// Core
import React from "react"

// Components
import CloseBtn from "../closeBtn"

// Styles
import styles from "./panel.module.scss"

// Assets
import mobileBg from "./assets/mobile.jpg"
import desktopBg from "./assets/desktop.png"
import desktopBgWebP from "./assets/webp/desktop.webp"

const Panel = ({
  preHeading,
  heading,
  panelOpen,
  setPanelOpen,
  zIndex,
  buttonPos,
}) => {
  return (
    <div
      className={`${styles.panel} ${panelOpen ? styles.open : ""} ${
        panelOpen ? `open` : ""
      } ${styles.homePanel}`}
      onClick={() => setPanelOpen(false)}
      style={{ zIndex: ` ${zIndex ? zIndex : ``}` }}
    >
      <div className={styles.panelInner} onClick={e => e.stopPropagation()}>
        <img className={styles.mobileBg} src={mobileBg} alt="" />
        <picture className={styles.desktopBg}>
          <source srcSet={desktopBgWebP} type="image/webp" />
          <source srcSet={desktopBg} type="image/jpeg" />
          <img src={desktopBg} alt="" />
        </picture>
        <div className={`${styles.inner}`}>
          <h5>{preHeading}</h5>
          <p className={`h1 large`}>{heading}</p>
          <p>
            With partners from environmental bodies, industry and community
            organisations we have developed a creative vision of what Scotland
            could look like in a future where we have chosen to act positively
            to address the climate emergency.
          </p>
          <p>This interactive vision takes 8 to 10 minutes to explore.</p>
          <p>
            To talk to us about this project or our work on a Sustainable
            Future, and to share your visions of a sustainable Scotland, contact
            us at{" "}
            <a href="mailto:scotland@nesta.org.uk" tabIndex="-1">
              scotland@nesta.org.uk
            </a>
          </p>
        </div>
        <CloseBtn event={() => setPanelOpen(false)} buttonPos={buttonPos} />
      </div>
    </div>
  )
}

export default Panel

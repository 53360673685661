// Core
import React from "react"

// Components
import { setCursorState } from "../cursor/set"

// Styles
import styles from "./wideBtnNoLink.module.scss"

// Assets
import Bg from "./assets/bg.inline.svg"
import NarrowBg from "./assets/narrowBg.inline.svg"
import Outline from "./assets/outline.inline.svg"
import Texture from "./assets/texture.png"
import NarrowTexture from "./assets/narrowTexture.png"
import Arrow from "./assets/arrow.png"

const WideBtnNoLink = React.forwardRef(({
  children,
  to,
  url,
  clickEvent,
  exit,
  entry,
  label,
  fontSize = 16,
  width,
  height = 47,
  outline,
  arrow,
}, ref) => {
  return (
    <a
      href="/"
      className={`button homeButton ${styles.wideBtn} ${
        outline ? styles.outline : null
      }`}
      onClick={clickEvent}
      onMouseEnter={() => setCursorState("pointer", true)}
      onMouseLeave={() => setCursorState("pointer", false)}
      aria-label={label}
      style={{
        fontSize: fontSize + "px",
        height: height + "px",
        width: width + "px",
      }}
      ref={ref}
    >
      {!outline && width > 200 ? (
        <React.Fragment>
          <Bg />
          <img
            className={styles.texture}
            src={Texture}
            width={221}
            height={47}
            alt=""
          />
        </React.Fragment>
      ) : !outline ? (
        <React.Fragment>
          <NarrowBg />
          <img
            className={styles.texture}
            src={NarrowTexture}
            width={161}
            height={47}
            alt=""
          />
        </React.Fragment>
      ) : (
        <Outline />
      )}
      {children}
      {arrow ? (
        <img
          className={styles.arrow}
          src={Arrow}
          width={23}
          height={14}
          alt=""
        />
      ) : (
        ``
      )}

      <span className={styles.hoverCircle}></span>
    </a>
  )
})

export default WideBtnNoLink

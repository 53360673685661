// Core
import React, { useRef } from "react"

// Components
import { setCursorState } from "../cursor/set"

// Styles
import styles from "./iBtn.module.scss"

// Assets
import Bg from "./assets/bg.inline.svg"
import I from "./assets/i.png"
import Texture from "./assets/texture.png"

const IBtn = ({ toggleState, toggleEvent, mobileHidden }) => {
  return (
    <button
      className={`button ${styles.iBtn} iBtn ${
        mobileHidden ? styles.mobileHidden : ""
      }`}
      aria-label="Open Learn More Panel"
      onClick={() => toggleEvent(!toggleState)}
      onMouseEnter={() => setCursorState("pointer", true)}
      onMouseLeave={() => setCursorState("pointer", false)}
    >
      <Bg />
      <img
        className={styles.texture}
        src={Texture}
        width={47}
        height={47}
        alt=""
      />
      <img className={styles.icon} src={I} width={4.5} height={18} alt="" />
      <span className={styles.hoverCircle}></span>
    </button>
  )
}

export default IBtn

const radians = (degrees) => {
  var pi = Math.PI
  return degrees * (pi / 180)
}

const drawImage = ({ ctx, cWidth, cHeight, image, x, y, parallax, w, h, displayW, displayH, offset = {x: 0, y: 0, r: 0}, rotationPoint = {x: 0, y: 0}, currentSprite = 1, rowCount = 10 }) => {
  let parallaxPx = parallax.factor * parallax.amount * 50,
      drawFrom = {
        x: (((x + parallaxPx) * -1) + (offset.x)),
        y: ((y * -1) + (offset.y))
      },
      drawSize = {
        w: displayW,
        h: displayH
      }

  if (offset.r) {
    ctx.translate(drawFrom.x + (rotationPoint.x), drawFrom.y + (rotationPoint.y))
    ctx.rotate(radians(offset.r))
    ctx.translate(-drawFrom.x - (rotationPoint.x), -drawFrom.y - (rotationPoint.y))
  }

  image && ctx.drawImage(
    image, // Image src
    w * (((currentSprite - 1) % rowCount) + 1) - w,
    h * Math.floor((currentSprite - 1) / 10), // Sample from co-ords
    w,
    h, // Sample to co-ords
    drawFrom.x,
    drawFrom.y, // Canvas draw from co-ords
    drawSize.w,
    drawSize.h// Canvas draw size
  )

  if (offset.r) {
    ctx.translate(drawFrom.x + (rotationPoint.x), drawFrom.y + (rotationPoint.y))
    ctx.rotate(radians(-offset.r))
    ctx.translate(-drawFrom.x - (rotationPoint.x), -drawFrom.y - (rotationPoint.y))
  }
}

const drawImages = (images = [], cSize, ctx, cursorPosX) => {
  for (let x = 0; x < images.length; x++) {
    (images[x].size && !images[x].hidden) && drawImage({
      ctx: ctx,
      cWidth: cSize.w,
      cHeight: cSize.h,
      image: images[x].el,
      x: 0, y: 0,
      parallax: {factor: images[x].parallax || 0, amount: cursorPosX / cSize.w - .5},
      w: images[x].size.w, h: images[x].size.h,
      displayW: images[x].displaySize ? images[x].displaySize.w : images[x].size.w, displayH: images[x].displaySize ? images[x].displaySize.h : images[x].size.h,
      offset: images[x].offset,
      rotationPoint: images[x].rotationPoint,
      currentSprite: images[x].currentSprite,
      rowCount: images[x].rowCount
    })
  }
}

export { drawImages }
// Core
import React, {useState, useEffect, useRef} from "react"
import { useTriggerTransition } from 'gatsby-plugin-transition-link'
import { isMobile } from "react-device-detect"

// Components
import { setCursorPos, setCursorState } from "../../components/cursor/set"

// Styles
import styles from "./illustrationFill.module.scss"

const IllustrationFill = ({ playing = false, draw = null, leaveEvent }) => {
  const [introComplete] = useState(false),
        [outroStarted, setOutroStarted] = useState(false),
        [animationFrame, setAnimationFrame] = useState(null)

  const canvasRef = useRef(),
        cSize = useRef({
          w: null,
          h: null,
          aspectRatio: null,
          imageW: null,
          imageH: null,
          imageX: null,
          imageY: null,
        })

  const setSize = (canvas) => {
    const { width, height } = canvas.getBoundingClientRect()

    if (canvas.width !== width || canvas.height !== height) {
      const ratio = window.devicePixelRatio || 1
      const context = canvas.getContext('2d')
      canvas.width = width * ratio
      canvas.height = height * ratio
      context.scale(ratio, ratio)
      cSize.current.w = canvas.offsetWidth
      cSize.current.h = canvas.offsetHeight
      cSize.current.aspectRatio = canvas.offsetWidth / canvas.offsetHeight
      let imageRatio = 1.778
      cSize.current.imageW = (cSize.current.aspectRatio <= imageRatio ? cSize.current.h * imageRatio : cSize.current.w)
      cSize.current.imageH = (cSize.current.aspectRatio > imageRatio ? cSize.current.w / imageRatio : cSize.current.h)
      cSize.current.imageX = (cSize.current.aspectRatio <= imageRatio ? (cSize.current.imageW - cSize.current.w) / 2 : 0)
      cSize.current.imageY = (cSize.current.aspectRatio > imageRatio ? (cSize.current.imageH - cSize.current.h) / 2 : 0)
      cSize.current.scale = cSize.current.imageW / 3840
      return true
    }

    return false
  }

  useEffect(() => {
    if (!draw) return

    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    let frameCount = 0
    let imageRatio = 1.778

    cSize.current.w = canvas.offsetWidth
    cSize.current.h = canvas.offsetHeight
    cSize.current.aspectRatio = canvas.offsetWidth / canvas.offsetHeight
    cSize.current.imageW = (cSize.current.aspectRatio <= imageRatio ? cSize.current.h * imageRatio : cSize.current.w)
    cSize.current.imageH = (cSize.current.aspectRatio > imageRatio ? cSize.current.w / imageRatio : cSize.current.h)
    cSize.current.imageX = (cSize.current.aspectRatio <= imageRatio ? (cSize.current.imageW - cSize.current.w) / 2 : 0)
    cSize.current.imageY = (cSize.current.aspectRatio > imageRatio ? (cSize.current.imageH - cSize.current.h) / 2 : 0)
    cSize.current.scale = cSize.current.imageW / 3840

    const render = () => {
      setSize(canvas)
      frameCount++
      context.clearRect(0, 0, context.canvas.width, context.canvas.height)
      setCursorPos(null, cursorPos, .15)
      draw(cSize.current, context, cursorPos.current.prevX, frameCount)
      setAnimationFrame(window.requestAnimationFrame(render))
    }

    if (!animationFrame) setAnimationFrame(window.requestAnimationFrame(render))

    return () => {
      animationFrame && window.cancelAnimationFrame(animationFrame)
    }
  }, [draw])

  // useEffect(() => {
  //   if (!leave) return
  // }, [draw])

  const distanceFromCenter = (x1, y1) => {
    let xs = window.innerWidth / 2 - x1,
        ys = window.innerHeight / 2 - y1

    xs *= xs
    ys *= ys

    return Math.sqrt(xs + ys)
  }

  // Cursor tracking
  const cursorPos = useRef({
          prevX: 0,
          prevY: 0,
          x: 0,
          y: 0
        })

  const trackMouse = (e) => {
    cursorPos.current.x = e.clientX
    cursorPos.current.y = e.clientY
  }

  // Transition out
  const triggerTransition = useTriggerTransition({
    exit: {
      trigger: leaveEvent,
      length: 6,
      zIndex: 5
    },
    entry: {
      delay: 0,
      zIndex: 10
    },
  })

  const dotGrowOut = useRef(null)

  const startCountOut = (e) => {
    if (outroStarted) return;

    if (dotGrowOut.current) dotGrowOut.current.kill()
    // dotGrowOut.current = gsap.to(portalDot.current, { scale: .6, duration: .8, ease: "linear", onComplete: () => setOutroStarted(true)})
  }

  const stopCountOut = (e) => {
    if (outroStarted) return;
    console.log("STOP")
    if (dotGrowOut.current) dotGrowOut.current.kill()
    // dotGrowOut.current = gsap.to(portalDot.current, { scale: 0, duration: .4, ease: "power2.in" })
  }

  return (
    <div
      className={styles.illustrationWrap}
      onMouseMove={!isMobile ? (e) => {
        trackMouse(e)
        setCursorState('canvas', introComplete)
      } : null}
      onMouseDown={!isMobile && introComplete ? startCountOut : null}
      onMouseUp={!isMobile && introComplete ? stopCountOut : null}
      onMouseLeave={() => setCursorState('canvas', false)}
      >
      <canvas className={styles.illustration} ref={canvasRef} />
    </div>
  )
}

export default IllustrationFill
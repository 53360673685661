import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React, { useEffect, useRef, useState, useContext } from "react"
import debounce from "lodash.debounce"
import Player from "@vimeo/player"

// Context
import { Context } from "../../context"

//For transition from video
import { useTriggerTransition } from "gatsby-plugin-transition-link"

// Utils
import { maintainAspectRatio } from "../../utils/video"

//Components
import UnderlineBtn from "../underlineBtn"

// Styles
import styles from "./home-video.module.scss"

const VideoComponent = props => {
  const { videoProps } = props,
    { videoPlaying } = props,
    { setVideoPlaying } = props,
    { audio } = props,
    { videoPlayBtnWithAudio } = props,
    { videoPlayBtnMuted } = props,
    { pageRef } = props

  const [state, setState] = useContext(Context)

  // const triggerTransition = useTriggerTransition({
  //   exit: {
  //     length: 0.5,
  //     zIndex: 5,

  //     trigger: ({ node, e, exit, entry }) => console.log(node, e, exit, entry),
  //   },
  //   entry: {
  //     delay: 0,
  //     length: 0.5,
  //     state: {
  //       from: "/",
  //     },
  //     zIndex: 10,
  //     trigger: ({ node, e, exit, entry }) => console.log(node, e, exit, entry),
  //   },
  //   ...state,
  // })

  const videoRef = useRef(),
    videoIframe = useRef(),
    videoAspectRatio = useRef(),
    playerRef = useRef(),
    embedRef = useRef()

  const resizeVideo = debounce(maintainAspectRatio, 500)

  const [videoCode, setVideoCode] = useState(videoProps[2].desktop)

  let playerVar

  useEffect(() => {
    const { current } = videoRef.current

    if (window) {
      if (window.innerWidth < 764) {
        setVideoCode(videoProps[0].mobile)
      } else if (window.innerWidth >= 764 && window.innerWidth < 1240) {
        setVideoCode(videoProps[1].tablet)
      } else {
        setVideoCode(videoProps[2].desktop)
      }
    }
    playerRef.current = new Player(videoIframe.current)
    const player = playerRef.current

    videoPlayBtnWithAudio.current.addEventListener("click", e => {
      setState(state => ({
        ...state,
        playAudio: true,
        audioScreenClicked: true,
      }))
      e.preventDefault()
      player.play()
      videoRef.current.setAttribute("data-playing", true)
      player.on("ended", onFin)
    })

    videoPlayBtnMuted.current.addEventListener("click", e => {
      setState(state => ({
        ...state,
        playAudio: false,
        audioScreenClicked: true,
      }))
      e.preventDefault()
      player.setMuted(true)
      player.play()
      videoRef.current.setAttribute("data-playing", true)
      player.on("ended", onFin)
    })

    if (!videoPlaying) {
      Promise.all([player.getVideoWidth(), player.getVideoHeight()]).then(
        function (dimensions) {
          var width = dimensions[0]
          var height = dimensions[1]

          videoAspectRatio.current = width / height

          resizeVideo(videoIframe.current, videoAspectRatio.current)

          window.addEventListener(
            "resize",
            resizeVideo.bind(
              null,
              videoIframe.current,
              videoAspectRatio.current
            ),
            false
          )
        }
      )
    }

    const onFin = () => {
      if (embedRef) embedRef.current.style.opacity = 0
      if (playerRef) playerRef.current.pause()
      if (pageRef.current) {
        pageRef.current.classList.add("video-finished")
      }
      document.querySelector(".homeSkip").click()
    }

    return () => {
      if (player) {
        // player.destroy()
        window.removeEventListener("resize", debounce(maintainAspectRatio, 500))
      }
    }
  }, [videoPlaying])

  const destroyVid = () => {
    setTimeout(() => {
      embedRef.current.style.opacity = 0
      playerRef.current.pause()
      if (pageRef.current) {
        pageRef.current.classList.add("video-finished")
      }
    }, 10)
  }

  return (
    <div ref={videoRef} className={styles.videoWrap} data-playing={false}>
      <div className={styles.embedContainer} ref={embedRef}>
        <iframe
          src={`https://player.vimeo.com/video/${videoCode}?&&title=0&sidedock=0&controls=0&autopause=1&muted=0`}
          height="315"
          width="560"
          className={`video ${styles.video}`}
          id={`heroVideo`}
          frameBorder="0"
          title={`Introductory Video to Nesta's This Must Be The Place`}
          allow="autoplay"
          ref={videoIframe}
        ></iframe>
      </div>

      <UnderlineBtn
        to={"/direct-democracy"}
        clickEvent={() => destroyVid()}
        exit={{
          length: 0.5,
          zIndex: 5,
        }}
        entry={{
          delay: 0,
          length: 0.5,
          state: {
            from: "/",
          },
          zIndex: 10,
        }}
        classList={`homeSkip`}
      >
        <span className={`skipBtn`}>Skip Intro</span>
      </UnderlineBtn>
    </div>
  )
}

export default VideoComponent

// Core
import React from "react"

// Components
import { setCursorState } from "../cursor/set"

// Styles
import styles from "./underlineBtnNoLink.module.scss"

// Line
import Line from "./assets/underline.png"

const UnderlineBtnNoLink = React.forwardRef(({
  children,
  to,
  clickEvent,
  exit,
  entry,
  label,
}, ref) => {
  return (
    <a
      href="/"
      className={`button homeButton ${styles.underlineBtn}`}
      onClick={clickEvent}
      onMouseEnter={() => setCursorState("pointer", true)}
      onMouseLeave={() => setCursorState("pointer", false)}
      aria-label={label}
      ref={ref}
    >
      {children}
      <span className={styles.lineWrap}>
        <span className={styles.line}>
          <img src={Line} alt="" />
        </span>
        <span className={styles.line}>
          <img src={Line} alt="" />
        </span>
      </span>
    </a>
  )
})

export default UnderlineBtnNoLink

// Core
import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"

// Components
import { setCursorState } from "../cursor/set"

// Styles
import styles from "./underlineBtn.module.scss"

// Line
import Line from "./assets/underline.png"

const UnderlineBtn = ({
  children,
  to,
  clickEvent,
  exit,
  entry,
  label,
  classList,
}) => {
  return (
    <TransitionLink
      className={`button ${styles.underlineBtn} ${classList ? classList : ``}`}
      onClick={clickEvent}
      onMouseEnter={() => setCursorState("pointer", true)}
      onMouseLeave={() => setCursorState("pointer", false)}
      aria-label={label}
      to={to}
      exit={
        exit
          ? exit
          : {
              length: 1,
              zIndex: 5,
            }
      }
      entry={
        entry
          ? entry
          : {
              delay: 0,
              zIndex: 10,
            }
      }
    >
      {children}
      <span className={styles.lineWrap}>
        <span className={styles.line}>
          <img src={Line} alt="" />
        </span>
        <span className={styles.line}>
          <img src={Line} alt="" />
        </span>
      </span>
    </TransitionLink>
  )
}

export default UnderlineBtn

// Core
import "react-app-polyfill/ie9"
import "react-app-polyfill/stable"
import "core-js"
import React, { useState, useRef, useEffect, useContext } from "react"
import { gsap } from "gsap"

// Context
import { Context } from "../context"

// Utils
import { loadAssets, animateSprites } from "../utils/canvas"

// Components
import SEO from "../components/seo"
import IllustrationFill from "../components/illustrationFill"
import Panel from "../components/panel/home-panel"
import { drawImages } from "../components/illustrationFill/images"
import UnderlineBtnNoLink from "../components/underlineBtnNoLink"
import WideBtnNoLink from "../components/wideBtnNoLink"
import IBtn from "../components/iBtn"
import Video from "../components/home-video"

// Style
import styles from "./home.module.scss"

// Assets
import Logo from "./assets/logo.inline.svg"
import textLogo from "./assets/text-logo.png"
import TitleMask from "./assets/title-mask.inline.svg"

import treeLMobileImg from "./assets/mobile/tree-l-mobile.png"
import treeLImg from "./assets/tree-l.png"
import treeRMobileImg from "./assets/mobile/tree-r-mobile.png"
import treeRImg from "./assets/tree-r.png"
import house1Img from "./assets/house-1.png"
import house2Img from "./assets/house-2.png"
import house3Img from "./assets/house-3.png"
import house4Img from "./assets/house-4.png"
import house5Img from "./assets/house-5.png"
// import star1Img from "./assets/star-1.png"
// import star2Img from "./assets/star-2.png"
// import star3Img from "./assets/star-3.png"
import turbineImg from "./assets/turbine.png"
import turbineBaseImg from "./assets/turbine-base.png"
import outlineMobileImg from "./assets/mobile/outline-mobile.png"
import outlineImg from "./assets/outline.png"

// Sprites
import droneImg from "./assets/sprites/drone.png"

const Home = () => {
  const [state, setState] = useContext(Context)

  const [reveal, setReveal] = useState(false)

  const [panelOpen, setPanelOpen] = useState(false),
    [videoPlaying, setVideoPlaying] = useState(false)

  const videoProps = [
    { mobile: 510589510 },
    { tablet: 510589410 },
    { desktop: 510589213 },
  ]

  const videoPlayBtnWithAudio = useRef(),
    videoPlayBtnMuted = useRef(),
    pageRef = useRef()

  const pageData = {
    index: 7,
    preHeading: "Nesta Scotland presents",
    heading: "An interactive leap forward to a sustainable Scotland.",
  }

  // Asset setup
  const outlineMobile = useRef({
      src: outlineMobileImg,
      el: null,
      baseOffset: { x: 0, y: 0 },
      animationOffset: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      displaySize: { w: 0, h: 0 },
      hidden: true,
    }),
    outline = useRef({
      src: outlineImg,
      el: null,
      baseOffset: { x: 0, y: 0 },
      animationOffset: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      displaySize: { w: 0, h: 0 },
      hidden: true,
    }),
    turbineBase1 = useRef({
      src: turbineBaseImg,
      el: null,
      baseOffset: { x: 0, y: 0 },
      animationOffset: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      displaySize: { w: 0, h: 0 },
    }),
    turbine1 = useRef({
      src: turbineImg,
      el: null,
      baseOffset: { x: 0, y: 0 },
      animationOffset: { x: 0, y: 0 },
      offset: { x: 0, y: 0, r: 0 },
      rotationPoint: { x: 0, y: 0 },
      displaySize: { w: 0, h: 0 },
    }),
    turbineBase2 = useRef({
      src: turbineBaseImg,
      el: null,
      baseOffset: { x: 0, y: 0 },
      animationOffset: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      displaySize: { w: 0, h: 0 },
    }),
    turbine2 = useRef({
      src: turbineImg,
      el: null,
      baseOffset: { x: 0, y: 0 },
      animationOffset: { x: 0, y: 0 },
      offset: { x: 0, y: 0, r: 0 },
      rotationPoint: { x: 0, y: 0 },
      displaySize: { w: 0, h: 0 },
    }),
    treeLMobile = useRef({
      src: treeLMobileImg,
      el: null,
      baseOffset: { x: 0, y: 0 },
      animationOffset: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      displaySize: { w: 0, h: 0 },
      hidden: true,
    }),
    treeL = useRef({
      src: treeLImg,
      el: null,
      baseOffset: { x: 0, y: 0 },
      animationOffset: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      displaySize: { w: 0, h: 0 },
      hidden: true,
    }),
    treeRMobile = useRef({
      src: treeRMobileImg,
      el: null,
      baseOffset: { x: 0, y: 0 },
      animationOffset: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      displaySize: { w: 0, h: 0 },
      hidden: true,
    }),
    treeR = useRef({
      src: treeRImg,
      el: null,
      baseOffset: { x: 0, y: 0 },
      animationOffset: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      displaySize: { w: 0, h: 0 },
      hidden: true,
    }),
    house1 = useRef({
      src: house1Img,
      el: null,
      baseOffset: { x: 0, y: 0 },
      animationOffset: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      displaySize: { w: 0, h: 0 },
    }),
    house2 = useRef({
      src: house2Img,
      el: null,
      baseOffset: { x: 0, y: 0 },
      animationOffset: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      displaySize: { w: 0, h: 0 },
    }),
    house3 = useRef({
      src: house3Img,
      el: null,
      baseOffset: { x: 0, y: 0 },
      animationOffset: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      displaySize: { w: 0, h: 0 },
    }),
    house4 = useRef({
      src: house4Img,
      el: null,
      baseOffset: { x: 0, y: 0 },
      animationOffset: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      displaySize: { w: 0, h: 0 },
    }),
    house5 = useRef({
      src: house5Img,
      el: null,
      baseOffset: { x: 0, y: 0 },
      animationOffset: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
      displaySize: { w: 0, h: 0 },
    }),
    drone = useRef({
      // parallax: 5,
      src: droneImg,
      el: null,
      baseOffset: { x: 0, y: 0 },
      animationOffset: { x: 0, y: 0 },
      offset: { x: 0, y: 0, r: 1 },
      size: { w: 166, h: 200 },
      displaySize: { w: 0, h: 0 },
      rotationPoint: { x: 0, y: 0 },
      currentSprite: 1,
      spriteFrames: 50,
      slowFactor: 1,
      loop: true,
    })

  // All assets in this scene (inc. sprites)
  const assets = useRef([
    outlineMobile.current,
    outline.current,
    turbineBase1.current,
    turbine1.current,
    turbineBase2.current,
    turbine2.current,
    treeLMobile.current,
    treeL.current,
    treeRMobile.current,
    treeR.current,
    house1.current,
    house2.current,
    house3.current,
    house5.current,
    house4.current,
    drone.current,
  ])

  // All sprite assets in this scene
  const sprites = useRef([drone.current])

  // Anything that should run on each canvas draw
  // (Should always end with drawImages)
  const draw = (cSize, ctx, cursorPos, frameCount) => {
    for (let x = 0; x < assets.current.length; x++) {
      assets.current[x].offset.x =
        assets.current[x].baseOffset.x + assets.current[x].animationOffset.x
      assets.current[x].offset.y =
        assets.current[x].baseOffset.y + assets.current[x].animationOffset.y
    }

    turbine1.current.offset.r++
    turbine2.current.offset.r++
    animateSprites(sprites.current, frameCount)
    drawImages(assets.current, cSize, ctx, cursorPos)
  }

  const applyMobileSizes = () => {
    let w = window.innerWidth,
      h = window.innerHeight

    treeL.current.hidden = true
    treeR.current.hidden = true
    outline.current.hidden = true

    outlineMobile.current.displaySize.w = w
    outlineMobile.current.displaySize.h = w * 0.213
    turbineBase1.current.displaySize.w = 4
    turbineBase2.current.displaySize.w = 4
    turbineBase1.current.displaySize.h = 60
    turbineBase2.current.displaySize.h = 60
    turbine1.current.displaySize.w = 80
    turbine1.current.displaySize.h = 80
    turbine2.current.displaySize.w = 80
    turbine2.current.displaySize.h = 80
    turbine1.current.rotationPoint.x = 40
    turbine1.current.rotationPoint.y = 40
    turbine2.current.rotationPoint.x = 40
    turbine2.current.rotationPoint.y = 40
    treeLMobile.current.displaySize.w = 110
    treeLMobile.current.displaySize.h = 181
    treeRMobile.current.displaySize.w = 120
    treeRMobile.current.displaySize.h = 161
    house1.current.displaySize.w = 125
    house1.current.displaySize.h = 102
    house2.current.displaySize.w = 125
    house2.current.displaySize.h = 60
    house3.current.displaySize.w = 125
    house3.current.displaySize.h = 92
    house5.current.displaySize.w = 109
    house5.current.displaySize.h = 54
    house4.current.displaySize.w = 150
    house4.current.displaySize.h = 104
    drone.current.displaySize.w = 41
    drone.current.displaySize.h = 50
    drone.current.rotationPoint.x = 20.5
    drone.current.rotationPoint.y = 2

    // Outlines
    outlineMobile.current.baseOffset.y =
      h - outlineMobile.current.displaySize.h - 42

    // Turbines
    turbineBase1.current.baseOffset.x = w * 0.125
    turbineBase1.current.baseOffset.y =
      h - turbineBase1.current.displaySize.h - 35
    turbineBase2.current.baseOffset.x = w * 0.9
    turbineBase2.current.baseOffset.y =
      h - turbineBase1.current.displaySize.h - 60
    turbine1.current.baseOffset.x = turbineBase1.current.baseOffset.x - 38
    turbine1.current.baseOffset.y = turbineBase1.current.baseOffset.y - 40
    turbine2.current.baseOffset.x = turbineBase2.current.baseOffset.x - 38
    turbine2.current.baseOffset.y = turbineBase2.current.baseOffset.y - 40

    // Trees
    treeRMobile.current.baseOffset.x = w - treeRMobile.current.displaySize.w

    // Houses
    house3.current.baseOffset.x = w / 2 - house3.current.displaySize.w / 2 - 5
    house3.current.baseOffset.y = h - house3.current.displaySize.h
    house2.current.baseOffset.x =
      house3.current.baseOffset.x - house2.current.displaySize.w + 5
    house2.current.baseOffset.y = h - house2.current.displaySize.h
    house1.current.baseOffset.x =
      house2.current.baseOffset.x - house1.current.displaySize.w + 5
    house1.current.baseOffset.y = h - house1.current.displaySize.h
    house4.current.baseOffset.x =
      house3.current.baseOffset.x + house3.current.displaySize.w - 10
    house4.current.baseOffset.y = h - house4.current.displaySize.h + 50
    house5.current.baseOffset.x =
      house4.current.baseOffset.x + house4.current.displaySize.w - 10
    house5.current.baseOffset.y = h - house5.current.displaySize.h

    // Drone
    drone.current.baseOffset.x = w * 0.7
    drone.current.baseOffset.y = h * 0.4

    treeLMobile.current.hidden = false
    treeRMobile.current.hidden = false
    outlineMobile.current.hidden = false
  }

  const applyDesktopSizes = () => {
    let w = window.innerWidth,
      h = window.innerHeight

    treeLMobile.current.hidden = true
    treeRMobile.current.hidden = true
    outlineMobile.current.hidden = true

    outline.current.displaySize.w = w
    outline.current.displaySize.h = w * 0.1925
    turbineBase1.current.displaySize.w = 10
    turbineBase2.current.displaySize.w = 10
    turbineBase1.current.displaySize.h = 181
    turbineBase2.current.displaySize.h = 181
    turbine1.current.displaySize.w = 220
    turbine1.current.displaySize.h = 220
    turbine2.current.displaySize.w = 220
    turbine2.current.displaySize.h = 220
    turbine1.current.rotationPoint.x = 110
    turbine1.current.rotationPoint.y = 110
    turbine2.current.rotationPoint.x = 110
    turbine2.current.rotationPoint.y = 110
    house1.current.displaySize.w = w * 0.225
    house1.current.displaySize.h = house1.current.displaySize.w * 0.821
    house2.current.displaySize.w = w * 0.225
    house2.current.displaySize.h = house2.current.displaySize.w * 0.484
    house3.current.displaySize.w = w * 0.225
    house3.current.displaySize.h = house3.current.displaySize.w * 0.735
    house5.current.displaySize.w = w * 0.225
    house5.current.displaySize.h = house5.current.displaySize.w * 0.69
    house4.current.displaySize.w = w * 0.225
    house4.current.displaySize.h = house4.current.displaySize.w * 0.5
    drone.current.displaySize.w = 83
    drone.current.displaySize.h = 105
    drone.current.rotationPoint.x = 41.5
    drone.current.rotationPoint.y = 5

    if (w > h) {
      treeL.current.displaySize.h = h * 0.6
      treeL.current.displaySize.w = treeL.current.displaySize.h / 1.465
      treeR.current.displaySize.h = h * 0.6
      treeR.current.displaySize.w = treeR.current.displaySize.h / 1.29
    } else {
      treeL.current.displaySize.w = w * 0.4
      treeL.current.displaySize.h = treeL.current.displaySize.w * 1.465
      treeR.current.displaySize.w = w * 0.4
      treeR.current.displaySize.h = treeR.current.displaySize.w * 1.29
    }

    // Trees
    treeR.current.baseOffset.x = w - treeR.current.displaySize.w

    // Outlines
    outline.current.baseOffset.y = h - outline.current.displaySize.h - 150

    // Turbines
    turbineBase1.current.baseOffset.x = w * 0.2
    turbineBase1.current.baseOffset.y = h * 0.65
    turbineBase2.current.baseOffset.x = w * 0.8
    turbineBase2.current.baseOffset.y = h * 0.75
    turbine1.current.baseOffset.x = turbineBase1.current.baseOffset.x - 105
    turbine1.current.baseOffset.y = turbineBase1.current.baseOffset.y - 110
    turbine2.current.baseOffset.x = turbineBase2.current.baseOffset.x - 105
    turbine2.current.baseOffset.y = turbineBase2.current.baseOffset.y - 110

    // Houses
    house3.current.baseOffset.x = w / 2 - house3.current.displaySize.w / 2 - 5
    house3.current.baseOffset.y = h - house3.current.displaySize.h
    house2.current.baseOffset.x =
      house3.current.baseOffset.x - house2.current.displaySize.w + 5
    house2.current.baseOffset.y = h - house2.current.displaySize.h
    house1.current.baseOffset.x =
      house2.current.baseOffset.x - house1.current.displaySize.w + 5
    house1.current.baseOffset.y = h - house1.current.displaySize.h
    house4.current.baseOffset.x =
      house3.current.baseOffset.x + house3.current.displaySize.w - 10
    house4.current.baseOffset.y = h - house4.current.displaySize.h
    house5.current.baseOffset.x =
      house4.current.baseOffset.x + house4.current.displaySize.w - 10
    house5.current.baseOffset.y = h - house5.current.displaySize.h

    // Drone
    drone.current.baseOffset.x = w * 0.75
    drone.current.baseOffset.y = h * 0.4

    treeL.current.hidden = false
    treeR.current.hidden = false
    outline.current.hidden = false
  }

  const applyStartPos = () => {
    let w = window.innerWidth,
      h = window.innerHeight

    house1.current.animationOffset.y = house1.current.displaySize.h
    house2.current.animationOffset.y = house2.current.displaySize.h
    house3.current.animationOffset.y = house3.current.displaySize.h
    house4.current.animationOffset.y = house4.current.displaySize.h
    house5.current.animationOffset.y = house5.current.displaySize.h

    if (window.innerWidth < 768) {
      outlineMobile.current.animationOffset.y =
        outlineMobile.current.displaySize.h + 42
      treeLMobile.current.animationOffset.x =
        treeLMobile.current.displaySize.w * -1
      treeRMobile.current.animationOffset.x = treeRMobile.current.displaySize.w
      turbineBase1.current.animationOffset.y =
        turbineBase1.current.displaySize.h + 75
      turbineBase2.current.animationOffset.y =
        turbineBase1.current.displaySize.h + 100
      turbine1.current.animationOffset.y =
        turbineBase1.current.animationOffset.y
      turbine2.current.animationOffset.y =
        turbineBase2.current.animationOffset.y
      drone.current.animationOffset.x = w
      drone.current.animationOffset.y = -300
    } else {
      outline.current.animationOffset.y = outline.current.displaySize.h + 150
      treeL.current.animationOffset.x = treeL.current.displaySize.w * -1
      treeR.current.animationOffset.x = treeR.current.displaySize.w
      turbineBase1.current.animationOffset.y = h * 0.35 + 110
      turbineBase2.current.animationOffset.y = h * 0.25 + 110
      turbine1.current.animationOffset.y =
        turbineBase1.current.animationOffset.y
      turbine2.current.animationOffset.y =
        turbineBase2.current.animationOffset.y
      drone.current.animationOffset.x = w
      drone.current.animationOffset.y = -300
    }
  }

  const startingPositions = () => {
    updatePositions()
    applyStartPos()
    // console.log("starting pos")
  }

  const updatePositions = () => {
    if (window.innerWidth < 768) {
      applyMobileSizes()
    } else {
      applyDesktopSizes()
    }
    // console.log("update pos")
  }

  const startingAnimations = () => {
    // Outline
    gsap.to(outlineMobile.current.animationOffset, {
      y: 0,
      duration: 2,
      ease: "power3.out",
    })
    gsap.to(outline.current.animationOffset, {
      y: 0,
      duration: 2,
      ease: "power3.out",
    })

    // Trees
    gsap.to(treeLMobile.current.animationOffset, {
      x: 0,
      duration: 3.5,
      ease: "power2.out",
    })
    gsap.to(treeRMobile.current.animationOffset, {
      x: 0,
      duration: 3.5,
      ease: "power2.out",
    })
    gsap.to(treeL.current.animationOffset, {
      x: 0,
      duration: 3.5,
      ease: "power2.out",
    })
    gsap.to(treeR.current.animationOffset, {
      x: 0,
      duration: 3.5,
      ease: "power2.out",
    })

    // Turbines
    gsap.to(turbineBase1.current.animationOffset, {
      y: 0,
      duration: 2,
      ease: "power3.out",
    })
    gsap.to(turbineBase2.current.animationOffset, {
      y: 0,
      duration: 2,
      ease: "power3.out",
    })
    gsap.to(turbine1.current.animationOffset, {
      y: 0,
      duration: 2,
      ease: "power3.out",
    })
    gsap.to(turbine2.current.animationOffset, {
      y: 0,
      duration: 2,
      ease: "power3.out",
    })

    // House
    gsap.to(house1.current.animationOffset, {
      y: 0,
      duration: 3,
      ease: "power2.out",
    })
    gsap.to(house2.current.animationOffset, {
      y: 0,
      duration: 2.8,
      ease: "power3.out",
      delay: 0.2,
    })
    gsap.to(house3.current.animationOffset, {
      y: 0,
      duration: 2.6,
      ease: "power3.out",
      delay: 0.4,
    })
    gsap.to(house4.current.animationOffset, {
      y: 0,
      duration: 2.8,
      ease: "power3.out",
      delay: 0.2,
    })
    gsap.to(house5.current.animationOffset, {
      y: 0,
      duration: 3,
      ease: "power2.out",
    })

    // Drone
    gsap.fromTo(
      drone.current.offset,
      { r: 5 },
      { r: -5, duration: 2, ease: "power2.inOut", repeat: -1, yoyo: true }
    )

    if (window.innerWidth < 768) {
      gsap.to(drone.current.animationOffset, {
        y: window.innerHeight * -0.1,
        duration: 8,
        ease: "back.out(1.5)",
        delay: 0.3,
      })
      gsap.to(drone.current.animationOffset, {
        x: window.innerWidth * -0.5,
        duration: 10,
        ease: "back.out(1.2)",
        delay: 0.3,
      })
      gsap.to(drone.current.animationOffset, {
        y: 0,
        duration: 10,
        ease: "power2.inOut",
        delay: 8.3,
      })
      gsap.to(drone.current.animationOffset, {
        x: 0,
        duration: 10,
        ease: "power2.inOut",
        delay: 10.3,
      })
    } else {
      gsap.to(drone.current.animationOffset, {
        y: window.innerHeight * 0.1,
        duration: 8,
        ease: "back.out(1.5)",
        delay: 0.3,
      })
      gsap.to(drone.current.animationOffset, {
        x: window.innerWidth * -0.5,
        duration: 10,
        ease: "back.out(1.2)",
        delay: 0.3,
      })
      gsap.to(drone.current.animationOffset, {
        y: 0,
        duration: 10,
        ease: "power2.inOut",
        delay: 8.3,
      })
      gsap.to(drone.current.animationOffset, {
        x: 0,
        duration: 10,
        ease: "power2.inOut",
        delay: 10.3,
      })
    }
  }

  const setPlayAudio = playAudio => {
    setState(state => ({
      ...state,
      playAudio: playAudio,
      audioScreenClicked: true,
    }))
  }

  // Animate in
  useEffect(() => {
    if (!reveal) {
      startingPositions()
    }

    let revealTimeout = setTimeout(() => {
      setReveal(true)
    }, 1000)

    let animationsTimeout = setTimeout(() => {
      startingAnimations()
    }, 1500)

    window.addEventListener("resize", updatePositions)

    return () => {
      if (revealTimeout) clearTimeout(revealTimeout)
      if (animationsTimeout) clearTimeout(animationsTimeout)
      window.removeEventListener("resize", updatePositions)
    }
  }, [startingPositions])

  // Load images
  useEffect(() => {
    loadAssets(assets.current)
  }, [])

  return (
    <div
      className={`${styles.page} ${reveal ? styles.reveal : styles.hidden} ${
        videoPlaying ? styles.playing : ``
      }`}
      ref={pageRef}
    >
      <IllustrationFill playing={reveal} draw={draw} />
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className={styles.content}>
        <div className={styles.mainTitle}>
          <img
            src={textLogo}
            className={styles.textLogo}
            width={750}
            height={399}
            alt="This Must Be The Place"
          />
          <TitleMask />
        </div>

        <h4 className={styles.subtitle}>
          Shared visions of Scotland’s sustainable future
        </h4>
        <WideBtnNoLink
          // clickEvent={}
          width={221}
          arrow={true}
          ref={videoPlayBtnWithAudio}
        >
          <span>Listen to the story</span>
        </WideBtnNoLink>

        <br />
        <UnderlineBtnNoLink
          // clickEvent={e => {
          //   e.preventDefault()
          //   setPlayAudio(false)
          //   setVideoPlaying(true)
          // }}
          ref={videoPlayBtnMuted}
        >
          <span>Read the story</span>
        </UnderlineBtnNoLink>
      </div>

      <IBtn
        toggleState={panelOpen}
        toggleEvent={setPanelOpen}
        mobileHidden={state.playAudio}
      />
      <Panel
        preHeading={pageData.preHeading}
        heading={pageData.heading}
        zIndex={12}
        buttonPos={`top`}
        panelOpen={panelOpen}
        setPanelOpen={setPanelOpen}
      />
      <Video
        videoProps={videoProps}
        videoPlaying={videoPlaying}
        setVideoPlaying={setVideoPlaying}
        audio={state.playAudio}
        videoPlayBtnWithAudio={videoPlayBtnWithAudio}
        videoPlayBtnMuted={videoPlayBtnMuted}
        pageRef={pageRef}
      />
    </div>
  )
}

export default Home
